<template>
<div>
    <v-row v-if="showListLiquidation">
        <v-col>
            <s-crud title="Liquidaciones" ref="list" :config="configList" @rowSelected=liquidationItemSelected($event) remove noSearch add addNoFunction @add=addLiquidation() excel pdf @pdf="reportPdf($event)">
                <template v-slot:options>
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on" small elevation="0" @click=reportDetail(true)>
                                <v-icon style="font-size:16px;" >fas fa-file-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Descargar consumos detallados</span></v-tooltip>
                </template>
            </s-crud>
        </v-col>
    </v-row>
    <v-row v-if="!showListLiquidation">
        <v-col>
            <s-crud ref="liquidation" title="Liquidación Comedor" @getFullData="getDataMeals($event)" :filter="filter" :config="config" @doubleClick="doubleClick($event)" excel>
                <template v-if="!showListLiquidation" v-slot:options>
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on" small elevation="0" @click="exitEdit()">
                                <v-icon  style="font-size:16px;">mdi-arrow-left-bold</v-icon>
                            </v-btn>
                        </template>
                        <span>Atrás</span></v-tooltip>
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on" small elevation="0" @click="loadSAP()" :loading="loadingSAP">
                                <v-icon style="font-size:16px;" >far fa-arrow-alt-circle-up</v-icon>
                            </v-btn>
                        </template>
                        <span>Subir a SAP</span></v-tooltip>
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on" small elevation="0" @click="showLiquidationCheck()">
                                <v-icon style="font-size:16px;">fas fa-tasks</v-icon>
                            </v-btn>
                        </template>
                        <span>Revisar consumos</span></v-tooltip>
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on" @click=reportDetail() small elevation="0" :loading=loadingReportDetail>
                                <v-icon style="font-size:16px;" >fas fa-file-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Descargar consumos detallados</span></v-tooltip>
                </template>
                <template v-slot:filter>
                    <v-col>
                        <v-row justify="center">
                            <v-col cols="12" lg="2" style="padding-bottom:0px">
                                <s-select dense outlined label="Tipo Planilla" v-model="filter.TypeWorker" hide-details="" :items="itemsPayRoll"></s-select>
                            </v-col>
                            <v-col cols="12" lg="2" md="6" v-if="filter.TypeWorker != 0&&filter.TypeWorker!=2" style="padding-bottom:0px">
                                <s-select label="Año" :items="years" outlined hide-details dense v-model="year"></s-select>
                            </v-col>
                            <v-col cols="12" lg="2" md="6" v-if="filter.TypeWorker == 14 " style="padding-bottom:0px">
                                <s-select label="Mes" :items="itemsMonth" outlined hide-details dense v-model="month"></s-select>
                            </v-col>

                            <!-- <v-col lg="3" v-if="filter.TypeWorker == 13 || filter.TypeWorker == 1" style="padding-bottom:0px">
                                <s-select small-chips attach="" multiple label="N° Semana" dense hide-details outlined :items="itemsWeek" v-model="week"></s-select>
                            </v-col> -->

                            <v-col cols="12" lg="2" md="6" v-if="filter.TypeWorker == 13 || filter.TypeWorker == 1">
                                <s-periods label="Periodo Semana" v-model="week" :year="year" :type="1" return-object></s-periods>
                            </v-col>
                            <v-col cols="12" lg="2" md="6">
                                <s-date label="Fecha Inicio" v-model='filter.BeginDate'/>
                            </v-col>
                            <v-col cols="12" lg="2" md="6"> 
                                <s-date label="Fecha Fin" v-model='filter.EndDate'></s-date>
                            </v-col>
                        </v-row>

                        <v-row justify="center">
                            <v-col cols="12" md="4">
                                <v-card elevation="1">
                                    <h3 class="text-center subtitle-1">DESCUENTO TRABAJADOR</h3>
                                    <v-divider></v-divider>
                                    <v-data-table dense :headers="headerDiscount" :items="meals" :disable-sort="true" item-key="name" :hide-default-footer="true" class="elevation-1"></v-data-table>
                                </v-card>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-card elevation="1">
                                    <h3 class="text-center subtitle-1">FACTURA</h3>
                                    <v-divider></v-divider>
                                    <v-data-table dense :headers="headerLocal" :items="meals" :disable-sort="true" item-key="name" :hide-default-footer="true" class="elevation-1"></v-data-table>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-card elevation="1">
                                    <h3 class="text-center subtitle-1 ">TOTAL</h3>
                                    <v-divider></v-divider>
                                    <v-data-table dense :headers="headerTotal" :items="meals" :disable-sort="true" item-key="name" :hide-default-footer="true" class="elevation-1"></v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
            </s-crud>
        </v-col>
    </v-row>

    <v-dialog v-model="showCheck" v-if="showCheck&& filter.TypeWorker!=1" persistent max-width="70%">
        <v-card>
            <v-toolbar dense elevation="2" flat class="text-center">
                <v-btn icon @click="closeCheck()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Revisar Consumos</v-toolbar-title>
            </v-toolbar>

            <v-col >
                <s-crud title="Consumos detallados" :filter="filterCheck" excel :config="configCheck">
                  
                    <template v-slot:StatusLiquidation={row}>
                        <v-simple-checkbox v-if='filter.TypeWorker!=2' style="cursor:pointer" color="primary" :ripple=false :disabled='(row.StatusLiquidation==4||row.StatusLiquidation==2)?true:false' :value="getStatusNoExceptions(row)
                         " @click='setStatusLiquidation(row)'></v-simple-checkbox>
                        <v-simple-checkbox v-else :style="{cursor:row.StatusLiquidation==4?'default': 'pointer'}" color="primary" :ripple=false :disabled='(row.StatusLiquidation==4||row.StatusLiquidation==2)?true:false' :value="getStatusExceptions(row)
                         " @click='setStatusLiquidation(row)'></v-simple-checkbox>
                    </template>
                </s-crud>
            </v-col>
            <v-divider></v-divider>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
import Service from '../../services/Service';
import MealServiceReportDetail from "./../../services/DinningHall//MealConsumptionDetail";
import MealService from "./../../services/DinningHall//MealConsumptionCostCenterService";
import LiquidationCheckService from "./../../services/DinningHall/LiquidationCheckService";
import LiquidationService from "./../../services/DinningHall/LiquidationService";
export default {
    name: "ConsumptionMealReport",
    components: {},
    data: () => ({
        loadingReportDetail: false,
        meals: [],
        item: null,
        showListLiquidation: true,
        showCheck: false,
        loadingSAP: false,
        itemsWeek: [],
        week: null,
        period: 2,
        month: null,
        year: null,
        filterCheck: {
            BeginDate: new Date().toISOString().substr(0, 10),
            EndDate: new Date().toISOString().substr(0, 10),
            TypeWorker: 14,
        },
        configCheck: {
            model: { McsID: "ID", McsReservationDate: 'date', StatusLiquidation: '', BeginDate: 'date', EndDate: 'date', SecUpdate: 'date' },
            service: LiquidationCheckService,
            headers: [

                { text: "Estado", value: "StatusLiquidation", width: '12' },
                { text: "ID", value: "McsID", width: '7' },
                { text: "Nombre Completo", value: "McsPersonName", },
                { text: "FechaAtención", value: "McsReservationDate", },
                { text: "Tipo Plato", value: "MealTypeText", },
                { text: "Tipo Plato", value: "MealTypeText", },
                { text: "Inicio Contrato", value: "BeginDate", },
                { text: "Fin Contrato", value: "EndDate", },
                { text: "Fecha de Cese", value: "SecUpdate", },
            ],
        },
        configList: {
            model: { LqdID: 'ID', DateDraft: 'date', LqdBeginDate: 'date', LqdEndDate: 'date', DateOrder: 'date' },
            service: LiquidationService,
            headers: [
                { text: "N° Liquidación", value: "LqdID", },
                { text: "Periodo", value: "LqdGlosa", },

                { text: "Fecha Subida", value: "DateDraft", },
                { text: "Tipo Liquidación", value: "TypeLiquidationText", },
                { text: "Fecha Inicio", value: "LqdBeginDate", },
                { text: "Fecha Fin", value: "LqdEndDate", },
                { text: "Número Orden", value: "NumOrder", },
                { text: "Fecha Orden", value: "DateOrder", },
                { text: "Total Liquidación", value: "LqdTotal", },
                { text: "Descuento Trabajador", value: "LqdDiscountWorker", },
            ],
        },
        headerDiscount: [
            { text: "TIPO", value: "TypeMealText" },
            { text: "CNT", value: "McsQuantity" },
            { text: "PRC", value: "McsDiscount" },
            { text: "DESC", value: "DiscountTotal" },
        ],
        headerLocal: [
            { text: "TIPO", value: "TypeMealText" },
            { text: "CNT", value: "McsQuantity" },
            { text: "PRC", value: "McsPriceLocal" },
            { text: "DESC", value: "PaymentLocal" },
        ],
        headerTotal: [
            { text: "TIPO", value: "TypeMealText" },
            { text: "CNT", value: "McsQuantity" },
            { text: "PRC", value: "McsPrice" },
            { text: "DESC", value: "Total" },
        ],
        filter: {
            BeginDate: new Date().toISOString().substr(0, 10),
            EndDate: new Date().toISOString().substr(0, 10),
            TypeWorker: 14,
            periodo: 1
        },
        itemsPayRoll: [
            { value: 14, text: "EMPLEADO" },
            { value: 13, text: "OBRERO" },
            { value: 1, text: "SIN DESCUENTO" },
            { value: 2, text: "EXCEPCIÓNES" },
        ],
        config: {
            model: { McsID: "ID" },
            service: MealService,
            headers: [
                { text: "ID", value: "McsID" },
                { text: "CCE", value: "CceID" },
                { text: "Centro Costo", value: "CenterCostText", width: "40%" },
                { text: "Linea Negocio", value: "BusinessLineText", width: "40%" },
                { text: "Tipo Costo", value: "TypeCostText", width: "40%" },
                { text: "Cant", value: "McsQuantity" },
                // { text: "No Consumidas", value: "NotConsumed" },
                //  { text: "N° Visitas", value: "Visits" },
                { text: "Pago Trabajador", value: "McsDiscount" },
                { text: "Pago Local", value: "PaymentLocal" },
                { text: "Total", value: "Total" },
            ],
        },
    }),
    created() {
        this.itemsMonth = this.$const.Months;

        for (let i = 1; i <= 53; i++) {
            this.itemsWeek.push({
                text: i,
                value: i,
            });
        }

        this.years = this.$fun.getTop5LastYears();
        this.year = this.$moment().year();
        this.restartFilter();
    },
    watch: {

        month() {
            this.selection();
        },
        week() {
            // console.log("(this.week.length", this.week);
            this.filter.BeginDate = this.week.PdsDateBegin;
            this.filter.EndDate = this.week.PdsDateEnd;
        },
        "filter.TypeWorker"() {
            this.restartFilter();
            // this.month = 1;
            //   this.week = [1];
            this.showCheck = false;
        },
        year() {
            this.week   =[];
            //this.selection();
            //  if (this.filter.TypeWorker == 14) this.month = 1;
            //    else this.week = [1];
        },
    },
    methods: {
        restartFilter() {
            this.month = null;
            this.week = [];
            this.filter.EndDate = this.$moment().add('days', -1).format("YYYY-MM-DD");
            this.filter.BeginDate = this.$moment().format("YYYY-MM-DD");
        },
        liquidationItemSelected(items) {
            if (items.length > 0) this.item = items[0];
            else this.item = null;
        },
        closeCheck() {
            this.showCheck = false;
            this.$refs.liquidation.refresh();
        },
        exitEdit() {
            this.showListLiquidation = true;
           // this.$refs.list.refresh();
        },
        reportDetail(liquidation) {
            if (liquidation) {
                if (this.item != null) {
                    this.loadingReportDetail = true;
                    let obj = { filter: {} }
                    obj.filter.TypeWorkerText = this.item.TypeLiquidationText;
                    obj.filter.LqdID = this.item.LqdID;
                    obj.filter.BeginDate = this.item.LqdBeginDate;
                    obj.filter.EndDate = this.item.LqdEndDate;
                    obj.searchText = '';
                    obj.length = -1;
                    obj.start = 0;
                    obj.isLevelAdmin = 0;
                    MealServiceReportDetail.excel(obj).then((r) => {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, 'REPORTE DE CONSUMOS ' + this.item.TypeLiquidationText);
                        this.loadingReportDetail = false;
                    }, (e) => {
                        this.loadingReportDetail = false;
                    });
                }
            } else {
                console.log('sssss ', liquidation)
                let typeLiquidationText = 'OBREROS'
                if (this.filter.TypeWorker == 14) typeLiquidationText = 'EMPLEADOS'
                if (this.filter.TypeWorker == 2) typeLiquidationText = 'EXCEPCIONES'
                if (this.filter.TypeWorker == 1) typeLiquidationText = 'SIN DESCUENTO'
                let obj = { filter: { ...this.filter } }
                obj.filter.TypeWorkerText = typeLiquidationText;
                obj.searchText = '';
                obj.length = -1;
                obj.start = 0;
                obj.isLevelAdmin = 0;
                MealServiceReportDetail.excel(obj).then((r) => {
                  
                    this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, 'REPORTE DE CONSUMOS ' + typeLiquidationText);
                });
            }

        },
        getStatusExceptions(row) {
            if (row.StatusLiquidation == 3) return true;
            else if (row.StatusLiquidation == 4) return false;
        },
        getStatusNoExceptions(row) {
            if (row.StatusLiquidation == 1 || row.StatusLiquidation == 2) return true;
            else return false;
        },
        reportPdf(dt) {
            let obj = { LqdID: dt.LqdID, Period: dt.LqdGlosa, BeginDate: dt.LqdBeginDate, EndDate: dt.LqdEndDate }
            //  obj.Period = "SEMANA " + (this.week * 2 - 1) + " - " + this.week * 2;

            obj.TypeWorkerText = "LIQUIDACIÓN " + dt.TypeLiquidationText + " N°: "
            /*   if (this.filter.TypeWorker == 13) obj.TypeWorkerText = "LIQUIDACIÓN OBREROS N°: ";
               if (this.filter.TypeWorker == 14) {
                   obj.TypeWorkerText = "LIQUIDACIÓN EMPLEADOS N°: ";
                   obj.Period = this.month + " - " + this.year;
               }
               if (this.filter.TypeWorker == 1) obj.TypeWorkerText = "LIQUIDACIÓN VISITAS N°: ";
               if (this.filter.TypeWorker == 2) {
                   obj.TypeWorkerText = "LIQUIDACIÓN EXCEPCIONES N°: ";
                   obj.Period = this.month + " - " + this.year;
               }*/

            MealService.PDFConsumos(obj).then((r) => {
                if (r.status = 200) {
                    this.$fun.alert('Descargado Correctamente', 'success');
                    this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, 'Liquidación');
                }
            });
        },
        addLiquidation() {
            this.showListLiquidation = false;
        },
        setStatusLiquidation(row) {
            if (row.StatusLiquidation == 4 || row.StatusLiquidation == 2) { return; }
            this.$fun.alert('Seguro de Cambiar Estado', 'question').then((r) => {
                if (r.value) {
                    let newStatus = 0;
                    if (this.filter.TypeWorker == 2) {
                        if (row.StatusLiquidation == 3) newStatus = 4;
                    } else {
                        if (row.StatusLiquidation == 3) newStatus = 1;
                        else if (row.StatusLiquidation == 1) newStatus = 3;
                    }
                    if (newStatus != 0)
                        LiquidationCheckService.setStatusLiquidation({ StatusLiquidation: newStatus, McsID: row.McsID }).then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert('Estado Modificado', 'success');
                                row.StatusLiquidation = newStatus;
                            }
                        });
                }
            })
        },
        showLiquidationCheck() {
            this.showCheck = true;
            this.filterCheck.BeginDate = this.filter.BeginDate;
            this.filterCheck.EndDate = this.filter.EndDate;
            this.filterCheck.TypeWorker = this.filter.TypeWorker;
        },
        loadSAP() {
            this.$fun
                .alert("Seguro de Cargar liquidación a SAP", "question")
                .then((r) => {
                    if (r.value) {
                        this.loadingSAP = true;
                        if (this.$fun.getUserSAPInfo() == null)
                            this.$fun.alert("Credenciales SAP Incorrectas", "warning");

                        let period = this.month + " - " + this.year;
                        if (this.filter.TypeWorker == 13 || this.filter.TypeWorker == 1){
                            period = "SEMANA ";
                            for(var i =0 ;i<this.week.length;i++)
                           { if(i==0) period=period+this.week 
                            else period=period+" - "+this.week 
                            }
                            }
                        else if (this.filter.TypeWorker == 2) period = "" + this.$moment().format('L')
                        MealService.loadSAP(
                            this.filter,
                            this.$fun.getUserSAPInfo().UsrID,
                            period
                        ).then(
                            (r) => {
                                if (r.status == 200)
                                    this.$fun.alert(
                                        "Liquidacón cargada correctamente a SAP",
                                        "success"
                                    );
                                this.loadingSAP = false;
                            },
                            (e) => {
                                this.loadingSAP = false;
                            }
                        );
                    }
                });
        },
        selectionPeriod() {
            var mondayYear = this.$moment(this.year + "-" + this.month + "-01").day(
                1
            );
            this.filter.BeginDate = mondayYear
                .add((this.week - 1) * 7 * this.period, "days")
                .format("YYYY-MM-DD");
            this.filter.EndDate = mondayYear
                .add(7 * this.period - 1, "days")
                .format("YYYY-MM-DD");
        },
        selection() {
            if (this.filter.TypeWorker == 13 || this.filter.TypeWorker == 1) {} 
            else if (this.filter.TypeWorker == 14 || this.filter.TypeWorker == 2) {
                if(this.month!=null){
                let dd = 19;
                let m = this.month - 1;
                if (m < 1)
                    this.filter.BeginDate = this.$moment(this.year - 1 + "1220").format(
                        "YYYY-MM-DD"
                    );
                else {
                    let mo = m <= 9 ? "0" + m : m;
                    this.filter.BeginDate = this.$moment(
                            this.year.toString() + mo.toString() + "01"
                        )
                        .add(dd, "days")
                        .format("YYYY-MM-DD");
                }

                let d = 18;
                this.filter.EndDate = this.$moment(
                        this.year.toString() +
                        "" +
                        (this.month <= 9 ? "0" + this.month.toString() : this.month) +
                        "01"
                    )
                    .add(d, "days")
                    .format("YYYY-MM-DD");
            }
            }
        },
        getDataMeals(data) {
            console.log('AAAAAAAAAAAAA', data);
            if (data != null)
                this.meals = data.dataMeals != null ? data.dataMeals : [];
        },
    },
};
</script>
